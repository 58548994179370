<template>
  <div class="ChatStart__container">
    <v-col>
      <v-row style="align-items: center; margin-left: 0; justify-content: space-between; margin-top: 10px">
        <div>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon> mdi-account-group-outline </v-icon>
              </v-btn>
            </template>
            <v-list style="overflow: auto; height: 300px">
              <span style="padding: 15px; font-weight: bold">Workspaces:</span>
              <v-list-item
                  @click="selectWorkspace(null)"
              >
                <v-list-item-title><span style="color: #40acf6">None</span></v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-for="(item, i) in workspaces"
                  :key="i"
                  @click="selectWorkspace(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span>{{ this.chatWorkspace?.name || 'Select Workspace'}}</span>
        </div>
        <div style="margin-right: 35px">
          <v-btn icon @click.stop="isSettingsOpen=true">
            <v-icon> mdi-settings </v-icon>
          </v-btn>
        </div>
      </v-row>
    <v-row class="ChatStart__messageInput">
      <v-col style="position: relative">
        <v-textarea
            class="TextArea"
            outlined
            dense
            :placeholder="'Message'"
            hide-details="auto"
            novalidate
            :rows="5"
            v-model="message"
            id="message_input2"
            @keydown.enter.prevent=""
            :loading="sendMessageLoading"
        >
        </v-textarea>
        <v-file-input
            label="File input"
            hide-input
            v-model="files"
            class="ChatStart__attach_button"
            multiple
            accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
            @change="onFileChange"
        ></v-file-input>
        <v-btn class="ChatStart__send_button" icon small @click.stop="send()">
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
        <v-row class="ChatStart__files" v-if="filesAccumulated.length !== 0">
          <div class="ChatStart__filesContainer">
            <div v-for="(file, index) in filesAccumulated" :key="index" class="fileItem">
              <div style="position:relative; display: flex; justify-content: start">
                <button @click.stop="onFileDelete(index)" class="closeButton">
                  <span>&times;</span>
                </button>
                <img v-if="file.type.includes('image')" :src="createObjectURL(file)" class="image"/>
                <div style="height: 80px">
                  <img v-if="!file.type.includes('image')" class="document" :src="`${publicPath}/icons/file-icon.svg`"/>
                </div>
                <span style="top: 0px">
                   {{ file.name }}
                 </span>
              </div>
            </div>
          </div>
        </v-row>
        <v-btn
            v-if="(message?.trim()?.replaceAll('\n','') || '') !== '' && this?.chatWorkspace?.id"
            @click.stop="saveWorkspacePrompt"
            :loading="workspaceActionLoading"
            style="width: 100px; font-size: 8pt; background: #ddf0ff; margin-top: 7px; border: 1px #43c1f8 solid;"
            outlined
            dense
            name="Save Prompt"
            label="Save Prompt">
          Save Prompt
        </v-btn>
        <workspace-prompt @onPromptSelect="(data) => { this.message = data.prompt }"/>
      </v-col>
    </v-row>

    <div>
      <div class="ChatStart__sessions">
        <div class="titleWrapper">
          <span class="title">
          <v-icon small> mdi-forum-outline </v-icon> Your recent chats
          </span>
          <div class="viewAll">
            <span>View All</span>
            <v-btn icon small @click.stop="goToSessions()">
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="list"  v-if="!sessionListLoading">
          <div class="item" v-for="session in sessions" :key="session.id" @click="goToSession(session.id)">
            <span v-if="session?.workspace?.name" class="workspace">
              <v-icon small> mdi-account-group-outline </v-icon> {{ session?.workspace?.name }}
            </span>
            <v-icon small v-if="!session?.workspace?.name"> mdi-forum-outline </v-icon>
            <span class="sessionTitle">
              {{ session.name }}
            </span>
            <span class="recentActivity">
              {{ getTime(session.updatedAt) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    </v-col>
    <a-i-chat-settings :dialog="isSettingsOpen" @close="isSettingsOpen = false"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations
} = createNamespacedHelpers('AIChat');
const { mapGetters: workspacesGetters, mapActions: workspaceActions, mapMutations: workspacesMutations } = createNamespacedHelpers('workspace');

import constants from "@/util/constants";
import * as moment from "moment/moment";
import AIChatSettings from "@/views/ai-chat/ai-chat-settings/ai-chat-settings.vue";
import WorkspacePrompt from "@/views/ai-workspaces/workspace/workspace-prompts/workspace-prompts.vue";

export default {
  name: 'ChatStartPage',
  components: {WorkspacePrompt, AIChatSettings},
  data() {
    return {
      isMemoryEditOpen: false,
      memory: {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      sessionPage: 0,
      sessionLimit: 6,
      message: '',
      files: [],
      filesAccumulated: [],
      publicPath: constants.isDevelopment ? "" : "",
      selectedWorkspace: null,
      isSettingsOpen: false,
    }
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    ...AIChatGetters({
      sendMessageLoading: 'SEND_MESSAGE_LOADING',
      sessions: 'SESSIONS_LIST',
      sessionListLoading: 'SESSIONS_LIST_LOADING',
      chatWorkspace: 'GET_CHAT_WORKSPACE',
    }),
    ...workspacesGetters({
      workspaces: 'WORKSPACES_LIST',
      workspacesLoading: 'WORKSPACES_LIST_LOADING',
      workspaceActionLoading: 'IS_WORKSPACE_PROMPTS_ACTION_LOADING',
      selectedPrompt: 'SELECTED_WORKSPACE_PROMPT',
      workspacePrompts: 'WORKSPACE_PROMPTS',
    }),
  },
  methods: {
    ...AIChatActions(['sendMessage', 'getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt', 'getSessions']),
    ...AIChatMutations({
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setSendMessage: 'SET_SEND_MESSAGE',
      setChatWorkspace: 'SET_CHAT_WORKSPACE',
      setSessions: 'SET_SESSIONS_LIST'
    }),
    ...workspaceActions(['getWorkspaces', 'getWorkspaceSessions', 'updateWorkspace', 'updateWorkspacePrompt', 'createWorkspacePrompt']),
    ...workspacesMutations({
      pushToWorkspacePrompt: 'PUSH_TO_WORKSPACE_PROMPTS',
      setWorkspacePrompts: 'SET_WORKSPACE_PROMPTS',
    }),
    async saveWorkspacePrompt() {
      if(this.selectedPrompt?.id) {
        const prompt = await this.updateWorkspacePrompt({
          prompt: this.message,
          workspaceId: this.chatWorkspace.id,
          workspacePromptId: this.selectedPrompt?.id,
        });
        const prompts = [...this.workspacePrompts];
        const index = prompts.findIndex((item) => item?.id === prompt?.id);
        prompts[index] = prompt;
        this.setWorkspacePrompts([...prompts]);
        return;
      }
      const prompt = await this.createWorkspacePrompt({
        prompt: this.message,
        workspaceId: this.chatWorkspace.id,
      });
      this.pushToWorkspacePrompt(prompt);
    },
    createObjectURL(file) {
      return URL.createObjectURL(file)
    },
    getTime(date) {
      return moment(date).fromNow()
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    selectWorkspace(item) {
      this.setChatWorkspace(item);
    },
    onFileChange(event) {
      event.forEach(thisFile => {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`${thisFile.name} too big`)
          return;
        }
        this.filesAccumulated.push(thisFile)
      })
      this.files = [];
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async prepareFiles() {
      const prepared = await Promise.all(this.filesAccumulated.map(async (file) => {
        const base64 =  await this.toBase64(file)
        return {
          base64: base64,
          name: file.name,
          type: file.type
        }
      }));
      return prepared;
    },
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    async send() {
      if(this.message === '') {
        return;
      }
      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);

      this.setSendMessage({
        workspaceId: this.chatWorkspace?.id,
        workspace: this.chatWorkspace,
        files: this.filesAccumulated,
        messageValue,
        memory: this.memory,
      })

      this.$router.push({ path: `/ai-chat` });
    },
    async getInfo() {
      await this.getWorkspaces();
      await this.getSessions({ page: this.sessionPage, limit: this.sessionLimit })
    },
    goToSession(id) {
      this.$router.push({ path: `/ai-chat`, query: { session: id } });
    },
    goToSessions() {
      this.$router.push({ path: `/chat-sessions` });
    },
  },
  watch: {
    async 'selectedCustomer.customer_id'() {
      this.setChatWorkspace(null);
      this.setSessions([])
      await this.getInfo();
    },
  },
  async mounted() {
    const {workspaceId} = this.$route.params;
    await this.getInfo(workspaceId);
    document.getElementById('message_input2').addEventListener("keydown", async (e) => {
      const getCaret = (el) => {
        if (el.selectionStart) {
          return el.selectionStart;
        } else if (document.selection) {
          el.focus();
          const r = document.selection.createRange();
          if (r == null) {
            return 0;
          }
          const re = el.createTextRange(), rc = re.duplicate();
          re.moveToBookmark(r.getBookmark());
          rc.setEndPoint('EndToStart', re);
          return rc.text.length;
        }
        return 0;
      }

      const start = e.target.selectionStart
      const end = e.target.selectionEnd
      const caret = getCaret(e.target);
      if (e.key == "Enter" && e.ctrlKey == false && e.shiftKey == false && this.message.replaceAll('\n', '') !== '') {
        await this.send()
      }
      if (e.key == "Enter" && e.ctrlKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
      if (e.key == "Enter" && e.shiftKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
    })
    document.getElementById('message_input2').onpaste = (event) => {
      const items = (event?.clipboardData || event?.originalEvent?.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob)
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './chat-start-page';
</style>
